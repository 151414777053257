<template>
    <div class="print-body" id="wrap">
        <router-view/>
    </div>
</template>

<script>
export default {
  mounted() {
    document.getElementById("body").className = "body-print"
  },
}
</script>

<style lang="scss" scoped>
.print-body{
  width: 100%;
  display: flex;
  justify-content: center;

  background-color: #fff;
}
</style>