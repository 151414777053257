/**
 * Created by 胡子哥 on 2021/09/12.
 */
import {$http} from '../common/axios.js';
import {_CONFIG} from '../config/config.js';
class ServicesGlobal {
    constructor() {
        this.path = {
            api: _CONFIG.apiPath+"/boss",
            www: _CONFIG.apiPath+"/www",
            apiPublic:_CONFIG.apiPath+"/www/public",
        }
    }

    postParams(obj) {
        let params = new URLSearchParams();
        for (let key in obj) {
            params.append(key, obj[key]);
        }
        return params;
    }

    //获取验证码
    getImgCode(data){
        return $http.get(`${this.path.apiPublic}/imgCaptcha`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    upFile(data = {}) {
        let params = this.postParams(data);
        return $http.post(`${this.path.api}/articleClass/upFile`, params)
            .then(function (response) {
                return response.data;
            });
    }

    getQrCode(url){
        return $http.get(url)
            .then(function (response) {
                return response.data;
            });
    }

    getTableFields(data={}){
        return $http.get(`${this.path.api}/tools/getTableFields`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }

    // router /boss/team/exportExcel
    exportExcel(routerName,data) {
        let exportMap = {
            "activity_order": "/activityOrder/exportExcel",
            "team": "/team/exportExcel",
            "employee": "/employee/exportExcel",
        }
        let router = exportMap[routerName]
        return $http.get(`${this.path.api}${router}`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
    getQrCodeE(data={}){
        let params = this.postParams(data);
        return $http.post(`${this.path.api}/createEWM`,params)
            .then(function (response) {
                return response.data;
            });
    }

    processesShow(data={}){
        return $http.get(`${this.path.api}/processesShow`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
    problemDescriptionShow(data={}){
        return $http.get(`${this.path.api}/problemDescriptionShow`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
    rootCauseShow(data={}){
        return $http.get(`${this.path.api}/rootCauseShow`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
    supplierShow(data={}){
        return $http.get(`${this.path.api}/supplierShow`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
    deviceDetail(id,data={}) {
        return $http.get(`${this.path.api}/device/details/all/${id}`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
    recordShow(data={}){
        return $http.get(`${this.path.api}/record/show/all`,{params:data})
            .then(function (response) {
                return response.data;
            });
    }
}

export {
    ServicesGlobal
};