import { createRouter, createWebHashHistory } from 'vue-router'
import open from '@/layout/open'
import platform from '@/layout/platform'
import emptyPrint from '@/layout/emptyPrint'
import datav from "@/layout/Datav";

const openRoutes = [
    {
        path: '/login',
        component: open,
        redirect: '/login',
        name: "login",
        meta: { title: '登录', classify: 'login', icon: 'login', affix: true, isHistoryNav:0,isShow: 0 },
        children: [
            {
                path: '/login',
                component: () => import('@/views/login/index'),
                name: 'login',
                meta: { title: '登录', classify: 'login', icon: 'login', affix: true,isHistoryNav:0, isShow: 0 }
            }
        ]
    },
    {
        path: '/loginEmployee',
        component: open,
        redirect: '/loginEmployee',
        name: "loginEmployee",
        meta: { title: '员工-登录', classify: 'loginEmployee', icon: 'loginEmployee', affix: true, isHistoryNav:0,isShow: 0 },
        children: [
            {
                path: '/loginEmployee',
                component: () => import('@/views/loginEmployee/index'),
                name: 'loginEmployee',
                meta: { title: '员工-登录', classify: 'loginEmployee', icon: 'loginEmployee', affix: true,isHistoryNav:0, isShow: 0 }
            }
        ]
    },
    {
        path: '/loginSelect',
        component: open,
        redirect: '/loginSelect',
        name: "loginSelect",
        meta: { title: '登录选择', classify: 'loginSelect', icon: 'loginSelect', affix: true, isHistoryNav:0,isShow: 0 },
        children: [
            {
                path: '/loginSelect',
                component: () => import('@/views/loginSelect/index'),
                name: 'loginSelect',
                meta: { title: '登录选择', classify: 'loginSelect', icon: 'loginSelect', affix: true,isHistoryNav:0, isShow: 0 }
            }
        ]
    },
    {
        path: '/printDetails',
        component: emptyPrint,
        redirect: '/printDetails',
        name: "printDetails",
        meta: { title: '打印详情', classify: 'printDetails', icon: 'printDetails', affix: true, isShow: 0 },
        children: [
            {
                path: '/printDetails/:id',
                component: () => import('@/views/printDetails/index'),
                name: 'printTable',
                meta: { title: '打印详情', classify: 'printDetails', icon: 'printDetails', affix: true, isShow: 0 }
            },
            {
                path: '/authPage',
                component: () => import('@/views/printDetails/authPage'),
                name: 'authPage',
                meta: { title: '打印详情', classify: 'authPage', icon: 'authPage', affix: true, isShow: 0 }
            }
        ]
    },
]
const importRoutes = filterByKey([
    {
        path: "/import",
        component: platform,
        redirect: "/import",
        name: "import",
        meta: {
            title: "数据导入",
            classify: "import",
            icon: "import",
            affix: true,
            level: 5,
            isShow: 0,
            authClassify: "import",
            table: "",
        },
        children: [
            {
                path: "/import",
                component: () => import("@/views/import/index"),
                name: "printIndex",
                meta: {
                    title: "数据导入",
                    classify: "import",
                    icon: "import",
                    affix: true,
                    level: 5,
                    isShow: 1,
                    authClassify: "import",
                    table: "",
                },
            },
            {
                path: "/upload/:type",
                component: () => import("@/views/import/upload"),
                name: "uploadIndex",
                meta: {
                    title: "导入数据",
                    classify: "print",
                    icon: "print",
                    affix: true,
                    level: 5,
                    isShow: 1,
                    authClassify: "print",
                    table: "",
                },
            },
        ],
    },
]);


const toolsRoutes1 = filterByKey([
    {
        path: '/',
        component:platform,
        redirect: '/home',
        name: "home",
        meta: { title: '未完结红卡', classify: 'home', icon: 'home', affix: true,isHistoryNav:0, isShow: 1 },
        children: [
            {
                path: '/home',
                component: () => import('@/views/home/index'),
                name: 'homeIndex',
                meta: { title: '未完结红卡', classify: 'home', icon: 'home', affix: true,isHistoryNav:0, isShow: 1 }
            },
            {
                path: '/home/detail/:id',
                component: () => import('@/views/home/detail'),
                name: 'homeDetail',
                meta: { title: '详情', classify: 'home', icon: 'home',isHistoryNav:0, affix: true, isShow: 1 }
            }
        ]
    },
    {
        path: '/complete',
        component:platform,
        redirect: '/complete',
        name: "complete",
        meta: { title: '已完结红卡', classify: 'complete', icon: 'complete', affix: true,isHistoryNav:0, isShow: 1 ,authClassify: "complete"},
        children: [
            {
                path: '/complete',
                component: () => import('@/views/complete/index'),
                name: 'completeIndex',
                meta: { title: '已完结红卡', classify: 'complete', icon: 'complete', affix: true,isHistoryNav:0, isShow: 1 ,authClassify: "complete"}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/complete/detail'),
                name: 'completeDetail',
                meta: { title: '详情', classify: 'complete', icon: 'complete',isHistoryNav:0, affix: true, isShow: 1,authClassify: "complete"  }
            }
        ]
    },
    {
        path: "/datav",
        component: datav,
        redirect: "/datav/all",
        name: "datav",
        meta: {
            title: "活动数据",
            classify: "datav",
            icon: "datav",
            affix: true,
            isHistoryNav: 0,
            isShow: 0,
            role: 10,
            authClassify: "datav",
        },
        children: filterByKey([
            {
                path: "all",
                component: () => import("@/views/datav/all"),
                name: "datavIndex",
                meta: {
                    title: "数据统计",
                    classify: "datav",
                    icon: "datav",
                    affix: true,
                    isHistoryNav: 0,
                    isShow: 1,
                    role: 10,
                    authClassify: "datav",
                },
            },
        ]),
    },
])


const employeeRoutes = filterByKey([
    {
        path: '/employee',
        component:platform,
        redirect: '/employee/index',
        name: "employee",
        meta: { title: '员工管理', classify: 'employee', icon: 'employee', affix: true,isHistoryNav:1, isShow: 1 ,authClassify: "employee"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/employee/index'),
                name: 'employeeIndex',
                meta: { title: '员工管理', classify: 'employee', icon: 'employee',isHistoryNav:1, affix: true, isShow: 1,authClassify: "employee" }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/employee/detail'),
                name: 'employeeDetail',
                meta: { title: '员工详情', classify: 'employee', icon: 'employee',isHistoryNav:0, affix: true, isShow: 1,authClassify: "employee" }
            }
        ])
    },
])

const deviceRoutes = filterByKey([
    {
        path: '/device',
        component:platform,
        redirect: '/device/index',
        name: "device",
        meta: { title: '产品管理', classify: 'device', icon: 'device', affix: true,isHistoryNav:1, isShow: 1,authClassify: "device" },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/device/index'),
                name: 'deviceIndex',
                meta: { title: '产品管理', classify: 'device', icon: 'device',isHistoryNav:1, affix: true, isShow: 1 ,authClassify: "device"}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/device/detail'),
                name: 'deviceDetail',
                meta: { title: '产品详情', classify: 'device', icon: 'device',isHistoryNav:0, affix: true, isShow: 1,authClassify: "device" }
            }
        ])
    },
])

const registCardRoutes = filterByKey([
    {
        path: '/registCard',
        component:platform,
        redirect: '/registCard/index',
        name: "registCard",
        meta: { title: '登记红卡', classify: 'registCard', icon: 'registCard', affix: true,isHistoryNav:1, isShow: 1,authClassify: "registCard" },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/registCard/index'),
                name: 'registCardIndex',
                meta: { title: '登记红卡', classify: 'registCard', icon: 'registCard',isHistoryNav:1, affix: true, isShow: 1,authClassify: "registCard" }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/registCard/detail'),
                name: 'registCardDetail',
                meta: { title: '红卡详情', classify: 'registCard', icon: 'registCard',isHistoryNav:0, affix: true, isShow: 1,authClassify: "registCard" }
            }
        ])
    },
])

const applyCardRoutes = filterByKey([
    {
        path: '/applyCard',
        component:platform,
        redirect: '/applyCard/index',
        name: "applyCard",
        meta: { title: '红卡管理', classify: 'applyCard', icon: 'applyCard', affix: true,isHistoryNav:1, isShow: 1,authClassify: "applyCard" },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/applyCard/index'),
                name: 'applyCardIndex',
                meta: { title: '登记红卡', classify: 'applyCard', icon: 'applyCard',isHistoryNav:1, affix: true, isShow: 1,authClassify: "applyCard" }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/applyCard/detail'),
                name: 'applyCardDetail',
                meta: { title: '红卡详情', classify: 'applyCard', icon: 'applyCard',isHistoryNav:0, affix: true, isShow: 1,authClassify: "applyCard" }
            }
        ])
    },
])

const handleMRBRoutes = filterByKey([
    {
        path: '/handleMRB',
        component:platform,
        redirect: '/handleMRB/index',
        name: "handleMRB",
        meta: { title: 'MRB管理', classify: 'handleMRB', icon: 'handleMRB', affix: true,isHistoryNav:1, isShow: 1,authClassify: "handleMRB" },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/handleMRB/index'),
                name: 'handleMRBIndex',
                meta: { title: 'MRB处理管理', classify: 'handleMRB', icon: 'handleMRB',isHistoryNav:1, affix: true, isShow: 1,authClassify: "handleMRB" }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/handleMRB/detail'),
                name: 'handleMRBDetail',
                meta: { title: 'MEB处理详情', classify: 'handleMRB', icon: 'handleMRB',isHistoryNav:0, affix: true, isShow: 1,authClassify: "handleMRB" }
            }
        ])
    },
])

const repairRoutes = filterByKey([
    {
        path: '/repair',
        component:platform,
        redirect: '/repair/index',
        name: "repair",
        meta: { title: '返修管理', classify: 'repair', icon: 'repair', affix: true,isHistoryNav:1, isShow: 1,authClassify: "repair" },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/repair/index'),
                name: 'repairIndex',
                meta: { title: '返修管理', classify: 'repair', icon: 'repair',isHistoryNav:1, affix: true, isShow: 1,authClassify: "repair" }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/repair/detail'),
                name: 'repairDetail',
                meta: { title: '返修管理详情', classify: 'repair', icon: 'repair',isHistoryNav:0, affix: true, isShow: 1,authClassify: "repair" }
            }
        ])
    },
])

const sqeRegistRoutes = filterByKey([
    {
        path: '/sqeRegist',
        component:platform,
        redirect: '/sqeRegist/index',
        name: "sqeRegist",
        meta: { title: 'SQE登记', classify: 'sqeRegist', icon: 'sqeRegist', affix: true,isHistoryNav:1, isShow: 1 ,authClassify: "sqeRegist"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/sqeRegist/index'),
                name: 'sqeRegistIndex',
                meta: { title: 'SQE登记管理', classify: 'sqeRegist', icon: 'sqeRegist',isHistoryNav:1, affix: true, isShow: 1,authClassify: "sqeRegist" }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/sqeRegist/detail'),
                name: 'sqeRegistDetail',
                meta: { title: 'SQE登记详情', classify: 'sqeRegist', icon: 'sqeRegist',isHistoryNav:0, affix: true, isShow: 1,authClassify: "sqeRegist" }
            }
        ])
    },
])

const lossRoutes = filterByKey([
    {
        path: '/loss',
        component:platform,
        redirect: '/loss/index',
        name: "loss",
        meta: { title: '生产管理', classify: 'loss', icon: 'loss', affix: true,isHistoryNav:1, isShow: 1 ,authClassify: "loss"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/loss/index'),
                name: 'lossIndex',
                meta: { title: '生产管理', classify: 'loss', icon: 'loss',isHistoryNav:1, affix: true, isShow: 1,authClassify: "loss" }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/loss/detail'),
                name: 'lossDetail',
                meta: { title: '生产管理详情', classify: 'loss', icon: 'loss',isHistoryNav:0, affix: true, isShow: 1,authClassify: "loss" }
            }
        ])
    },
])

const reworkRoutes = filterByKey([
    {
        path: '/rework',
        component:platform,
        redirect: '/rework/index',
        name: "rework",
        meta: { title: '返工管理', classify: 'rework', icon: 'rework', affix: true,isHistoryNav:1, isShow: 1 ,authClassify: "rework"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/rework/index'),
                name: 'reworkIndex',
                meta: { title: '返工管理', classify: 'rework', icon: 'rework',isHistoryNav:1, affix: true, isShow: 1 ,authClassify: "rework"}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/rework/detail'),
                name: 'reworkDetail',
                meta: { title: '返工管理详情', classify: 'rework', icon: 'rework',isHistoryNav:0, affix: true, isShow: 1 ,authClassify: "rework"}
            }
        ])
    },
])

const pobManageRoutes = filterByKey([
    {
        path: '/pobManage',
        component:platform,
        redirect: '/pobManage/index',
        name: "pobManage",
        meta: { title: 'POB登记', classify: 'pobManage', icon: 'pobManage', affix: true,isHistoryNav:1, isShow: 1 ,authClassify: "pobManage"},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/pobManage/index'),
                name: 'pobManageIndex',
                meta: { title: 'POB登记', classify: 'pobManage', icon: 'pobManage',isHistoryNav:1, affix: true, isShow: 1,authClassify: "pobManage" }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/pobManage/detail'),
                name: 'pobManageDetail',
                meta: { title: 'POB登记详情', classify: 'pobManage', icon: 'pobManage',isHistoryNav:0, affix: true, isShow: 1 ,authClassify: "pobManage"}
            }
        ])
    },
])

const problemDescriptionRoutes = filterByKey([
    {
        path: '/problemDescription',
        component:platform,
        redirect: '/problemDescription/index',
        name: "problemDescription",
        meta: { title: '问题描述', classify: 'problemDescription', icon: 'problemDescription', affix: true,isHistoryNav:1, isShow: 0 },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/problemDescription/index'),
                name: 'problemDescriptionIndex',
                meta: { title: '问题描述', classify: 'problemDescription', icon: 'problemDescription',isHistoryNav:1, affix: true, isShow: 1 }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/problemDescription/detail'),
                name: 'problemDescriptionDetail',
                meta: { title: '问题描述详情', classify: 'problemDescription', icon: 'problemDescription',isHistoryNav:0, affix: true, isShow: 1 }
            }
        ])
    },
])

const rootCauseRoutes = filterByKey([
    {
        path: '/rootCause',
        component:platform,
        redirect: '/rootCause/index',
        name: "rootCause",
        meta: { title: '根本原因', classify: 'rootCause', icon: 'rootCause', affix: true,isHistoryNav:1, isShow: 0 },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/rootCause/index'),
                name: 'rootCauseIndex',
                meta: { title: '根本原因', classify: 'rootCause', icon: 'rootCause',isHistoryNav:1, affix: true, isShow: 1 }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/rootCause/detail'),
                name: 'rootCauseDetail',
                meta: { title: '根本原因详情', classify: 'rootCause', icon: 'rootCause',isHistoryNav:0, affix: true, isShow: 1 }
            }
        ])
    },
])

const processesRoutes = filterByKey([
    {
        path: '/processes',
        component:platform,
        redirect: '/processes/index',
        name: "processes",
        meta: { title: '工序问题', classify: 'processes', icon: 'processes', affix: true,isHistoryNav:1, isShow: 0 },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/processes/index'),
                name: 'processesIndex',
                meta: { title: '工序问题', classify: 'processes', icon: 'processes',isHistoryNav:1, affix: true, isShow: 1 }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/processes/detail'),
                name: 'processesDetail',
                meta: { title: '工序问题详情', classify: 'processes', icon: 'processes',isHistoryNav:0, affix: true, isShow: 1 }
            }
        ])
    },
])
const supplierRoutes = filterByKey([
    {
        path: '/supplier',
        component:platform,
        redirect: '/supplier/index',
        name: "supplier",
        meta: { title: '供应商', classify: 'supplier', icon: 'supplier', affix: true,isHistoryNav:1, isShow: 0 },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/supplier/index'),
                name: 'supplierIndex',
                meta: { title: '供应商', classify: 'supplier', icon: 'supplier',isHistoryNav:1, affix: true, isShow: 1 }
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/supplier/detail'),
                name: 'supplierDetail',
                meta: { title: '供应商详情', classify: 'supplier', icon: 'supplier',isHistoryNav:0, affix: true, isShow: 1 }
            }
        ])
    },
])
const teamRoutes = filterByKey([
    {
        path: '/team',
        component:platform,
        redirect: '/team/index',
        name: "team",
        meta: { title: '团队管理', classify: 'team', icon: 'team', affix: true,isHistoryNav:1,isShow: 0,role:9},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/team/index'),
                name: 'teamIndex',
                meta: { title: '团队管理', classify: 'team', icon: 'team', affix: true,isHistoryNav:1,isShow: 1 ,role:9}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/team/detail'),
                name: 'teamDetail',
                meta: {title: '团队详情', classify: 'team', icon: 'team', affix: true,isHistoryNav:0,isShow: 1,role:9}
            },
        ])
    },
    {
        path: '/admin',
        component:platform,
        redirect: '/admin/index',
        name: "admin",
        meta: { title: '团队管理员', classify: 'admin', icon: 'admin', affix: true,isHistoryNav:1, isShow: 0 },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/admin/index'),
                name: 'adminIndex',
                meta: { title: '管理员管理', classify: 'admin', icon: 'admin',isHistoryNav:1, affix: true, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/admin/detail'),
                name: 'adminDetail',
                meta: { title: '管理员详情', classify: 'admin', icon: 'admin',isHistoryNav:0, affix: true, isShow: 1 }
            }
        ])
    },
])

const systemRoutes = filterByKey([
    {
        path: '/boss',
        component: platform,
        redirect: '/boss/index',
        name: "boss",
        meta: {title: '平台管理员', classify: 'boss', icon: 'boss', affix: true,isHistoryNav:1, level: 2, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/boss/index'),
                name: 'bossIndex',
                meta: {title: '平台管理员', classify: 'boss', icon: 'boss', affix: true, isHistoryNav:1,level: 2, role: 2, isShow: 1}
            },
            {
                path: 'detail/:id',
                component: () => import('@/views/boss/detail'),
                name: 'bossDetail',
                meta: {title: '添加/编辑管理员', classify: 'boss', icon: 'boss', affix: true,isHistoryNav:0, level: 2, role: 2, isShow: 1}
            }
        ])
    },
    {
        path: '/bossAuth',
        component: platform,
        redirect: '/bossAuth/index/0',
        name: "bossAuth",
        meta: {title: '授权管理', classify: 'bossAuth', icon: 'bossAuth', affix: true,isHistoryNav:1, level: 2, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index/:id',
                component: () => import('@/views/bossAuth/index'),
                name: 'bossAuthIndex',
                meta: {title: '授权管理', classify: 'bossAuth', icon: 'bossAuth', affix: true,isHistoryNav:1, level: 2, role: 2, isShow: 1}
            },
            {
                path: 'options',
                component: () => import('@/views/bossAuth/options'),
                name: 'bossAuthOptions',
                meta: {title: '分类选项管理', classify: 'bossAuth', icon: 'bossAuth', affix: true,isHistoryNav:0, level: 2, role: 2, isShow: 1}
            }
        ])
    },
    {
        path: '/system',
        component:platform,
        redirect: '/system/index',
        name: "system",
        meta: { title: '系统配置', classify: 'system', icon: 'system', affix: true,isHistoryNav:1, isShow: 0 },
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/system/index'),
                name: 'systemIndex',
                meta: { title: '系统配置', classify: 'system', icon: 'system', affix: true,isHistoryNav:1, isShow: 1 }
            },
        ])
    },
])

const constantRoutes = [
    ...openRoutes,
    ...importRoutes,
    ...toolsRoutes1,
    ...employeeRoutes,
    ...deviceRoutes,
    ...registCardRoutes,
    ...applyCardRoutes,
    ...handleMRBRoutes,
    ...repairRoutes,
    ...sqeRegistRoutes,
    ...lossRoutes,
    ...reworkRoutes,
    ...pobManageRoutes,
    ...problemDescriptionRoutes,
    ...rootCauseRoutes,
    ...supplierRoutes,
    ...processesRoutes,
    ...teamRoutes,
    ...systemRoutes
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: constantRoutes
});

//TODO: 先实现下一级的过滤，
function filterByKey(arrayObj){
    arrayObj = arrayObj.filter((item)=>{
        return item["meta"]["isShow"] !== 0
    });

    return arrayObj
}

export {
    openRoutes,
    importRoutes,
    toolsRoutes1,
    employeeRoutes,
    deviceRoutes,
    registCardRoutes,
    applyCardRoutes,
    handleMRBRoutes,
    repairRoutes,
    sqeRegistRoutes,
    lossRoutes,
    reworkRoutes,
    pobManageRoutes,
    problemDescriptionRoutes,
    rootCauseRoutes,
    supplierRoutes,
    processesRoutes,
    teamRoutes,
    systemRoutes,
    constantRoutes,
    router
}