<template>
  <div class="site-header">
    <div class="start" v-if="title">
      <span class="title">{{ title }}</span>
    </div>
    <div class="start" v-else>
      <span class="unit-name">{{ systemConfig.title }}</span>
      <!--<span>【平台】</span>-->
    </div>

    <div class="history-nav-box">
      <ul class="history-nav" id="historyNav">
        <li v-for="(item,index) in historyNav" :key="index">
          <a @click="jumpByPath(item.path)" class="router-link" :class="{'router-link-active':routeClassify===item.classify}"
             :title="item.title">{{ item.title }}</a>
          <i class="icon-close close" @click="removeRouter(item,index)"
             :class="{'router-link-active':routeClassify===item.classify}"></i>
        </li>
      </ul>
    </div>

    <div class="end">
      <el-dropdown size="small" trigger="click" @command="handleUser">
        <a class="el-dropdown-link">
          <span class="u-name">{{ userInfo.real_name || userInfo.nickname || userInfo.username }}</span>
          <span class="u-auth-name" v-if="userInfo.auth_group_name">[{{ userInfo.auth_group_name }}]</span>
          <i class="icon-arrow-down"></i>
        </a>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import {_data, _methods, util} from '@/common/public.js'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ..._data,
      systemConfig: {},
      userInfo: {real_name: '', nickname: '', username: ''},
      historyNav: [],
      routeName: "",
      routeClassify:"",
    }
  },
  watch: {
    '$route': 'routeChange'
  },
  created() {
    this.systemConfig = util.getLocalStorage('systemConfig', {})
    this.userInfo = util.getLocalStorage('userInfo')
    this.routeName = this.$route.name
    this.routeClassify = this.$route.meta.classify
  },
  mounted() {
    let historyNav = sessionStorage.getItem("LB-HK-historyNav") || ""
    if (historyNav) {
      this.historyNav = JSON.parse(historyNav)
    }

    this.listenSessionStorageChanges()
  },
  methods: {
    ..._methods,
    logout() {
      util.setLocalStorage('TOKEN', '');
      util.setLocalStorage('userInfo', '');
      sessionStorage.setItem('LB-HK-historyNav', '')
      this.$router.push('Login');
    },
    handleUser(e) {
      //logout
      this[e]()
    },
    removeRouter(item, index) {
      this.historyNav.splice(index, 1)
      this.setStorage()
    },
    routeChange(to, from) {
      console.log("routeChange---", to, from)
      this.pageTitle = this.$route.meta.title
      this.routeName = this.$route.name
      this.routeClassify = this.$route.meta.classify

      if(!to.meta.isHistoryNav){
        return;
      }

      //黑名单中的不记录
      let blackList = "'',login,home,homeIndex,datav,datavIndex".split(",")
      if (blackList.indexOf(this.routeName) > -1) {
        return
      }

      //已经存在返回
      let index = this.historyNav.findIndex(value => {
        return value.name === this.routeName
      })
      if (index > -1) {
        return
      }

      this.historyNav.push({
        title: this.$route.meta.title,
        path: this.$route.fullPath,
        name: this.$route.name,
        classify:this.$route.meta.classify,
      })
      console.log("historyNav=>", this.historyNav)
      this.setStorage()
    },
    setStorage() {
      sessionStorage.setItem("LB-HK-historyNav", JSON.stringify(this.historyNav))
    },
    listenSessionStorageChanges() {
      window.addEventListener('setItem', () => {
        let historyNavDataString = sessionStorage.getItem('LB-HK-historyNav') || ""
        if (!historyNavDataString) {
          return
        }
        let historyNavData = JSON.parse(historyNavDataString)
        console.log("LB-HK-historyNav", historyNavData)
        this.historyNav = historyNavData.data
        this.routeName = historyNavData.to.name
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.site-header {
  --start-width: 180px;
  --end-width: 220px;
  --radius: 6px;

  display: flex;
  background: var(--bg-primary-2);
  height: 68px;
  color: var(--t-color);

  .start {
    width: var(--start-width);
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 20px;

    .unit-name {
      font-size: 20px;
      line-height: 20px;
    }

    .title {
      font-size: 22px;
      line-height: 22px;
    }

    display: none;
  }

  .end {
    /*width: var(--end-width);*/
    flex: 1;
    text-align: right;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;

    a {
      margin-left: 6px;
      display: flex;
      align-items: center;
      background-color: var(--bg-primary-4);
      padding: 8px 16px;
      border-radius: 30px;

      &:hover {
        background-color: var(--bg-primary-5);
      }
    }

    [class^='icon-'] {
      font-size: 12px;
      margin-left: 6px;
    }
  }

  .switch-user {
    cursor: pointer;
    /*background: #07c160;
    color: #fff;
    line-height: 34px;
    padding: 0 20px;
    border-radius: 4px;
    cursor: pointer;*/
  }

  .sim-btn {
    display: inline-block;
    background-color: var(--bg-primary-1);
    border-radius: 30px;
    line-height: 30px;
    padding: 0 14px;
    transition: all 0.3s;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      background-color: var(--bg-primary-hover);
    }
  }

  .u-name, .u-auth-name {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90px;
  }
  .u-auth-name {
    margin-left: 4px;
  }
}

.site-header-placeholder {
  height: 70px;
  margin-bottom: 16px;
}

.history-nav-box {
  width: calc(100vw - 60px - var(--end-width) - var(--sidebar-width));
  overflow: auto;
  padding-left: 14px;
  box-sizing: border-box;

  scrollbar-width: thin;
  scrollbar-color: var(--bg-primary-3) var(--bg-primary-4);
}

.history-nav {
  height: 100%;
  display: flex;
  align-items: center;

  li {
    display: flex;
    padding-top: 3px;
    margin-right: 4px;
  }

  .router-link, .close {
    height: 32px;
    display: flex;
    align-items: center;
  }

  .close {
    padding: 0 10px 0 5px;
    background-color: var(--bg-primary-1);
    font-size: 12px;
    border-radius: 0 var(--radius) var(--radius) 0;
    cursor: pointer;
    &:hover {
      background-color: var(--bg-primary);
      color: var(--color-danger);
    }
    &.router-link-active {
      background-color: var(--color-primary);
    }
  }

  .router-link {
    display: inline-block;
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 5px 0 10px;
    background-color: var(--bg-primary-1);
    border-radius: var(--radius) 0 0 var(--radius);

    font-size: 14px;

    &:hover {
      background-color: var(--bg-primary);
    }

    &.router-link-active {
      background-color: var(--color-primary);
    }
  }
}
</style>
